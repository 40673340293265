import React from 'react'
import { ItemNavi } from "./item_navi"
import { routes } from "../../root"
import {Outlet} from "react-router-dom"
import styles from './styles.module.scss'
import CustomIcon from "../../components/custom-icon"

const {
  'app': app,
  'app__content': appContent,
  'app__header': header,
  'app__header__controls': headerControls,
  'app__header__user': headerUser,
  'app__header__logo': headerLogo,
  'app__navi': navi,
  'app__navi__ul': naviUl,
  'app__navi__ul-items': naviUlItems,
  'app__footer': footer,
  'app__footer__license': footerLicense,
  'app__footer__license__logo': footerLicenseLogo,
  'app__footer__license__links': footerLicenseLinks,
  'app__footer__license__trademark': footerLicenseTrademark,
  'app__footer__contact': footerContact,
  'app__footer__contact__follow': footerContactFollow,
  'app__footer__contact__links': footerContactLinks
} = styles

const App = () => {
  return (<div className={app}>
    <div className={header}>
      <div className={headerControls}>
        <div className={headerLogo}>HEALTH AI</div>
        <nav className={navi}>
          <ul className={naviUl}>
            <div className={naviUlItems}>{routes.map((m, i) => <ItemNavi {...m} key={i} />)}</div>
          </ul>
        </nav>
      </div>
      <div className={headerUser}><CustomIcon name={'user'} />Herr Sachbearbeiter</div>
    </div>
    <div className={appContent}><Outlet /></div>
    <div className={footer}>
      <div className={footerLicense}>
        <div className={footerLicenseLogo}>HEALTH AI</div>
        <div className={footerLicenseLinks}>
          <span>Datenschutzerklärung</span>
          <span>Nutzungsbedingungen</span>
          <span>Impressum</span>
        </div>
        <div className={footerLicenseTrademark}>2022 © HEALTH AI Int. GmbH.</div>
      </div>
      <div className={footerContact}>
        <div className={footerContactFollow}>Follow us</div>
        <div className={footerContactLinks}>
          <CustomIcon name={'twitter'} />
          <CustomIcon name={'linkin'} />
          <CustomIcon name={'some-app-logo'} />
          <CustomIcon name={'facebook'} />
        </div>
      </div>
    </div>
  </div>)
}

export default App
