import React, { useState } from 'react'
import { TextField, Button } from '@mui/material'
import { useNavigate } from "react-router-dom";
import { login } from '../../domain/http'
import MuiButton from "../../components/mui-button"

type Props = {
  onLogin: () => Promise<void>
}

const LoginPage = (props: Props) => {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [invalidText, setInvalidText] = useState<string>('')

  let navigate = useNavigate();

  const onLogin = async () => {
    setInvalidText('')
    const resp = await login(username, password)
    if (resp.status === 'ok') {
      await props.onLogin()
      let path = '../versicherte';
      navigate(path);
    } else if (resp.status === 'error') {
      setError(resp.message)
    } else if (resp.status === 'unauthorized') {
      setInvalidText(resp.message || 'Invalid username or password')
    }
  }

  return <div className={'login'}>
    <div
      className={'paper'}
      onKeyPress={
        (e: React.KeyboardEvent) => e.code === 'Enter' && onLogin()
      }
    >
      <h1>Melden Sie sich bitte an</h1>
      <TextField
        margin="normal"
        data-test-id={'input-login'}
        label={'Nutzername'}
        value={username}
        helperText={invalidText}
        onChange={(e) => {
          setUsername(e.target.value);
        }}
      />
      <TextField
        margin="normal"
        data-test-id={'input-password'}
        label={'Passwort'}
        value={password}
        type={'password'}
        helperText={invalidText}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <b>{error}</b>

      <MuiButton
        content='Einloggen'
        onClick={onLogin}
        disabled={!username || !password}
      />
    </div>
  </div>
}

export default LoginPage
