import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Provider, useSelector } from "react-redux"
import { store } from "./store"
import NotFoundPage from "./routes/not_found"
import { getUser, ResponseBody } from './domain/http'
import Auth, { RawUser } from './domain/auth'
import App from "./routes/app"
import ArbeitsbereichPage from "./routes/arbeitsbereich"
import UsersPage from "./routes/users"
import LoginPage from "./routes/login"
import { ThemeProvider, createTheme } from "@mui/material"
import './root.scss'

import OpenSansLight from './fonts/OpenSans-Light.ttf'
import OpenSansBold from './fonts/OpenSans-Bold.ttf'
import OpenSansLightItalic from './fonts/OpenSans-LightItalic.ttf'
import OpenSansRegular from './fonts/OpenSans-Regular.ttf'
import OpenSansItalic from './fonts/OpenSans-Italic.ttf'
import OpenSansSemiBold from './fonts/OpenSans-SemiBold.ttf'
import OpenSansSemiBoldItalic from './fonts/OpenSans-SemiBoldItalic.ttf'

const theme = createTheme({
  typography: {
    fontFamily: 'OpenSans, sans-serif',
    allVariants: {
      fontFamily: 'OpenSans, sans-serif',
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        fontFamily: 'OpenSans, sans-serif'
      },
    },
  },
})

export const routes = [
  {
    element: ArbeitsbereichPage,
    label: 'ARBEITSBEREICH',
    path: 'arbeitsbereich',
  },
  {
    element: UsersPage,
    label: 'VERSICHERTE',
    path: 'versicherte'
  },
]

const Root = () => {
  const [auth, setAuth] = useState<Auth | undefined | null>(undefined)

  const getUserHandler = (response: ResponseBody<RawUser>) => {
    if ('body' in response) {
      const rawUser = response.body
      if (rawUser) {
        const auth = new Auth(
          rawUser.username,
          rawUser.firstName,
          rawUser.lastName,
          rawUser.email
        )
        setAuth(auth)
      }
    } else {
      setAuth(null)
    }
  }

  const onLogin = async () => {
    const user = await getUser()
    getUserHandler(user)
    console.log(user)
  }

  useEffect(() => {
    const logoutListener = () => {
      setAuth(null)
    }

    window.addEventListener('logout', logoutListener)
    getUser()
      .then(getUserHandler)

    return () => {
      window.removeEventListener('logout', logoutListener)
    }
  }, [])

  return <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<App />}>
            {routes
              .map(({ path, element: Component }, i) => (
                <Route
                  key={i}
                  path={`${path}`}
                  element={<Component />}
                />
              ))}
            <Route path={"/"} element={<Navigate replace to={"/login"} />} />
            <Route path={"/login"} key={5} element={<LoginPage onLogin={onLogin} />} />
            <Route path={"*"} element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
}

export default Root
