import React from 'react'
import { Box, Divider } from "@mui/material"
import CustomIcon from "../../components/custom-icon"
import styles from "./styles.module.scss"
import ArbeitsbereichTabs from "./arbeitsbereich-tabs"
import { useSelector } from "react-redux"
import { TStore } from "../../store"
import manSvg from '../../icons/man_circle.svg'
import womanSvg from '../../icons/woman_circle.svg'

const {
  'users-content': usersContent,
  'users-content__icon-label': iconLabel,
  'users-content__label': label,
  'users-content__label-wrapper': labelWrapper,
  'users-content__content': content,
  'users-content__content-wrapper': contentWrapper,
  'users-content__divider-wrapper': dividerWrapper,
} = styles

const ArbeitsbereichContent = () => {
  const { currentUserTab } = useSelector((state: TStore) => state.commonReducer)
  const { lastname, firstname, geburtsdatum, kv_number, rv_number,
    street, housenumber, city, age, telefon, personengruppe, iban,
    bic, plz, gender, insured_since } = currentUserTab.user
  const isGender = gender === 'Male' ? manSvg : womanSvg
  return (
    <div>
      <Box
        className={usersContent}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '140px',
          bgcolor: 'background.paper',
          color: 'text.secondary',
          '& svg': {
            m: 1.5,
          },
          '& hr': {
            mx: 0.5,
          },
        }}
      >
        <div className={label} style={{ marginRight: '10px' }}><img src={isGender} alt={''} height={80} width={80} /></div>
        <Divider orientation={"vertical"} variant={"middle"} flexItem />
        <div className={dividerWrapper}>
          <div className={contentWrapper}>
            <div className={iconLabel}><CustomIcon name={'person'} size={'12px'} viewBox={'0 0 12 12'} />Vorname, Name</div>
            <span className={content}>{lastname}, {firstname}</span>
          </div>
          <div className={contentWrapper}>
            <div className={iconLabel}><CustomIcon name={'date'} size={'12px'} viewBox={'0 0 12 12'} />Geburtsdatum</div>
            <span className={content}>{geburtsdatum} ({age})</span>
          </div>
          <div className={labelWrapper}>
            <div className={contentWrapper}>
              <div className={label}>KV-nr.</div>
              <span className={content} style={{ marginLeft: '0px', marginTop: '12px' }}>{kv_number}</span>
            </div>
            <div className={contentWrapper}>
              <div className={label}>RV-Nr.</div>
              <span className={content} style={{ marginLeft: '0px', marginTop: '12px' }}>{rv_number}</span>
            </div>
          </div>
        </div>

        <Divider orientation={"vertical"} variant={"middle"} flexItem />
        <div className={dividerWrapper}>
          <div className={contentWrapper}>
            <div className={iconLabel}>
              <CustomIcon name={'location'} size={'12px'} viewBox={'0 0 12 12'} />Adresse / Kontakt</div>
            <span className={content}>{street} {housenumber}, {plz} {city}</span>
          </div>
          <div className={contentWrapper}>
            <div className={iconLabel}>
              <CustomIcon name={'phone'} size={'12px'} viewBox={'0 0 12 12'} />Telefon</div>
            <span className={content}>{telefon}</span>
          </div>
          <div className={contentWrapper}>
            <div className={iconLabel}>
              <CustomIcon name={'globus'} size={'12px'} viewBox={'0 0 12 12'} />Staatsangehörigkeit</div>
            <span className={content}>deutsch</span>
          </div>
        </div>

        <Divider orientation={"vertical"} variant={"middle"} flexItem />
        <div className={dividerWrapper}>
          <div className={contentWrapper}>
            <div className={iconLabel}>
              <CustomIcon name={'list'} size={'12px'} viewBox={'0 0 12 12'} />Versicherungsstatus</div>
            <span className={content}>Versichert seit {insured_since}</span>
          </div>
          <div className={labelWrapper}>
            <div className={contentWrapper}>
              <div className={label}>KGA</div>
              <span className={content} style={{ marginLeft: '0px', marginTop: '12px' }}>Ja</span>
            </div>
            <div className={contentWrapper}>
              <div className={label}>KV</div>
              <span className={content} style={{ marginLeft: '0px', marginTop: '12px' }}>Ja</span>
            </div>
            <div className={contentWrapper}>
              <div className={label}>PV</div>
              <span className={content} style={{ marginLeft: '0px', marginTop: '12px' }}>Ja</span>
            </div>
          </div>
          <div className={contentWrapper}>
            <div className={iconLabel}>
              <CustomIcon name={'people'} size={'12px'} viewBox={'0 0 12 12'} />Personengruppe</div>
            <span className={content}>{personengruppe}</span>
          </div>
        </div>

        <Divider orientation={"vertical"} variant={"middle"} flexItem />
        <div className={dividerWrapper}>
          <div className={contentWrapper}>
            <div className={iconLabel}>
              <CustomIcon name={'list'} size={'12px'} viewBox={'0 0 12 12'} />IBAN</div>
            <span className={content}>{iban}</span>
          </div>
          <div className={contentWrapper}>
            <div className={iconLabel}>
              <CustomIcon name={'list'} size={'12px'} viewBox={'0 0 12 12'} />BIC</div>
            <span className={content}>{bic}</span>
          </div>
          <div className={contentWrapper}>
            <div className={iconLabel}>
              <CustomIcon name={'flag'} size={'12px'} viewBox={'0 0 12 12'} />Name der Bank</div>
            <span className={content}>Deutsche Bank</span>
          </div>
        </div>

        <Divider orientation={"vertical"} variant={"middle"} flexItem />
        <div className={dividerWrapper}>
          <div className={contentWrapper}>
            <div className={iconLabel}>
              <CustomIcon name={'persons'} size={'12px'} viewBox={'0 0 12 12'} />Verw. / Betreuungst.</div>
            <span className={content}>-</span>
          </div>
          <div className={contentWrapper}>
            <div className={iconLabel}>
              <CustomIcon name={'eye'} size={'12px'} viewBox={'0 0 12 12'} />Zuständigkeiten</div>
            <span className={content}>-</span>
          </div>
          <div className={labelWrapper}>
            <div className={contentWrapper}>
              <div className={label}>Exkl. Kundenbetreuer</div>
              <span className={content} style={{ marginLeft: '0px', marginTop: '12px' }}>-</span>
            </div>
            <div className={contentWrapper}>
              <div className={label}>Gesundheitsmanager</div>
              <span className={content} style={{ marginLeft: '0px', marginTop: '12px' }}>-</span>
            </div>
          </div>
        </div>
      </Box>
      <ArbeitsbereichTabs />
    </div>
  )
}

export default ArbeitsbereichContent