import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './styles.module.scss'
import {useSelector} from "react-redux"
import {TStore} from "../../store"
import CustomIcon from "../../components/custom-icon"

const {
  'app__navi__items': naviItems,
  'app__navi__active': active,
  'app__navi__icon-wrapper': iconWrapper,
  'app__navi__icon': icon,
} = styles

interface Navi {
  path: string,
  label: string
}

export const ItemNavi = (props: Navi) => {
  const {
    tabs
  } = useSelector((state: TStore) => state.commonReducer)
  const {
    path, label,
  } = props

  const labelControls = (label: string) => {
    if (label === 'ARBEITSBEREICH') {
      return `${label} (${tabs.length})`
    } else {
      return label
    }
  }

  return (
    <NavLink
      to={`${path}`}
      title={label}
      className={(navData) => (navData.isActive ? active : '')}
    >
      <div className={naviItems}>
        <span>{labelControls(label)}</span>
      </div>
    </NavLink>
  )
}