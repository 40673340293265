import React, { useState, useEffect, useCallback, ReactNode } from 'react'
import { Tabs, Tab, Box, Typography, styled } from "@mui/material"
import CustomIcon from "../custom-icon"
import styles from "./style.module.scss"
import { useAppDispatch } from "../../hooks"
import { updateCommon } from "../../slices/common-slice"
import { useSelector } from "react-redux"
import { TStore } from "../../store"

const {
  'mui-tabs__wrapper': tabsWrapper,
  'mui-tabs__close-button': closeButton,
} = styles

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    color: '#78909C',
    backgroundColor: '#78909C',
  },
})

const StyledTab = styled(Tab)({
  color: '#424242',
  fontSize: '10px',
  '&:hover': {
    color: '#78909C',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#78909C',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
})


const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props
  return (
    <div
      role={"tabpanel"}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: '4px' }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

type arrTabsType = {
  value: string,
  label: string,
  content: ReactNode,
  closable: boolean,
  id: number,
  onDelete?: (id: number) => void,
  disabled?: boolean
}

type TabsType = {
  tabsContent: arrTabsType[]
  isShowSettings?: boolean,
}

const MuiTabs = (props: TabsType) => {
  const dispatch = useAppDispatch()
  const { tabs } = useSelector((state: TStore) => state.commonReducer)
  const [activeTab, setActiveTab] = useState(0)
  const [totalTabs, setTotalTabs] = useState<arrTabsType[]>([])

  const { tabsContent, isShowSettings } = props

  useEffect(() => {
    setTotalTabs(tabsContent)
  }, [tabsContent])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const setCurrentUser = (item: any) => {
    item.user && dispatch(updateCommon({ currentUserTab: item }))
  }

  const handleClose = useCallback(
    (event, tabToDelete) => {
      event.stopPropagation()
      const tabToDeleteIndex = totalTabs.findIndex(tab => tab.id === tabToDelete.id)
      const updatedTabs = totalTabs.filter((tab, index) => {
        return index !== tabToDeleteIndex
      })
      const previousTab =
        totalTabs[tabToDeleteIndex - 1] ||
        totalTabs[tabToDeleteIndex + 1] ||
        {}
      setTotalTabs(updatedTabs)
      setActiveTab(previousTab.id)
    },
    [totalTabs]
  )

  return (
    <Box sx={{ width: '100%' }}>
      <StyledTabs
        aria-label={"secondary tabs example"}
        value={activeTab}
        onChange={handleChange}
        sx={{ height: '24px' }}
      >
        {totalTabs.map((item, key) => {
          return <StyledTab
            onClick={() => setCurrentUser(item)}
            disabled={item.disabled}
            label={
              (
                <span className={tabsWrapper}>
                  {" "}
                  <span>{item.label}</span>
                  {item.closable && (
                    <a
                      className={closeButton}
                      onClick={(event) => {
                        handleClose(event, item)
                        item.onDelete && item?.onDelete(item.id)
                        setActiveTab(0)
                        item !== tabs[0] ? setCurrentUser(tabs[0]) : setCurrentUser(tabs[1])
                      }}
                    >
                      <CustomIcon name={'close'} size={'16px'} />
                    </a>
                  )}
                </span>
              )
            } key={key}
          />
        })}
        {isShowSettings ? <span style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginRight: '10px' }}>
          <CustomIcon name={'settings'} />
        </span> : null}
      </StyledTabs>
      <Box>
        {totalTabs.map((item, key) => {
          return <TabPanel
            index={key}
            value={activeTab}
            key={key}
          >{item.content}</TabPanel>
        })}
      </Box>

    </Box>
  )
}

export default MuiTabs