
export type RawUser = {
  "id": string,
  "createdTimestamp": number,
  "username": string,
  "enabled": boolean,
  "totp": boolean,
  "emailVerified": boolean,
  "firstName": string,
  "lastName": string,
  "email": string,
  "disableableCredentialTypes": unknown[],
  "requiredActions": unknown[],
  "notBefore": number,
  "access": {
    "manageGroupMembership": boolean,
    "view": boolean,
    "mapRoles": boolean,
    "impersonate": boolean,
    "manage": boolean
  }
}

class Auth {
  constructor (
    private _username: string,
    private _firstName?: string,
    private _lastName?: string,
    private _email?: string) {
  }

  get username () {
    return this._username
  }

  get firstName () {
    return this._firstName
  }

  get lastName () {
    return this._lastName
  }

  get email () {
    return this._email
  }
}

export default Auth
