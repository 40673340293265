import React, { useEffect, useState } from 'react'
import styles from "./styles.module.scss"
import CustomIcon from "../../components/custom-icon"
import MuiButton from "../../components/mui-button"
import MuiTable from "../../components/mui-table"
import MuiModal from "../../components/mui-modal"
import MuiCheckbox from "../../components/mui-checkbox"
import { useSelector } from "react-redux"
import { TStore } from "../../store"
import { getPreventionMeasures } from "../../domain/api"
import { useAppDispatch } from "../../hooks"
import { heartAttackColor, heartAttackIcon, heartAttackLevel } from "../../domain/utils"

const {
  'users-content__ai-proposal-tab': aiProposalTab,
  'users-content__ai-proposal-tab__header': header,
  'users-content__ai-proposal-tab__content': content,
  'users-content__ai-proposal-tab__content__percent': contentPercent,
  'users-content__ai-proposal-tab__content__text': contentText,
  'users-content__ai-proposal-tab__additional-content': additionalContent,
  'users-content__ai-proposal-tab__button': button,
  'users-content__ai-proposal-tab__modal': modal,
  'users-content__ai-proposal-tab__modal__under-title-text': underTitleText,
  'users-content__ai-proposal-tab__modal__content-wrapper': contentWrapper,
  'users-content__ai-proposal-tab__modal__content-wrapper__zone': zone,
  'users-content__ai-proposal-tab__modal__content-wrapper__zone__check': check,
  'users-content__ai-proposal-tab__modal__content-wrapper__zone__text': text,
  'users-content__ai-proposal-tab__modal__content-wrapper__zone__date': date,
  'users-content__ai-proposal-tab__modal__content-wrapper__zone__date__title': dateTitle,
  'users-content__ai-proposal-tab__modal__content-wrapper__zone__date__content': dateContent,
  'users-content__ai-proposal-tab__confirm-modal': confirmModal,
} = styles

const proposalCells = [
  { header: 'ICD-10 Klassifikation', accessor: 'icd10' },
  { header: 'Kapitel', accessor: 'chapter' },
  { header: 'Gruppe', accessor: 'section' },
  { header: 'Krankheit', accessor: 'disease' },
  { header: 'Einfluss auf Risikoschätzung', accessor: 'strength' }
]

const AiProposalTab = () => {
  const dispatch = useAppDispatch()
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false)
  const { currentUserTab, prevention_measures } = useSelector((state: TStore) => state.commonReducer)
  const { user_id } = currentUserTab.user

  useEffect(() => {
    dispatch(getPreventionMeasures(user_id))
  }, [])

  const onOpenModal = () => {
    setIsOpenModal(true)
  }
  const onCloseModal = () => {
    setIsOpenModal(false)
  }
  const onConfirmModal = () => {
    setIsOpenModal(false)
    setIsOpenConfirm(true)
  }

  const onCloseConfirmModal = () => {
    setIsOpenConfirm(false)
  }

  const DialogContent = () => (
    <div className={modal}>
      <div className={underTitleText}>Basierend auf der Krankenhistorie und des
        prognostizierten Herzinfarktrisikos wären möglicherweise folgende Präventionsmaßnahmen sinnvoll.
        Wählen Sie eine oder mehrere aus und übersenden Sie die Empfehlungen an die versicherte Person.
      </div>
      <div className={contentWrapper}>
        {prevention_measures.map((item, key) => {
          const { name, street, housenumber, plz, city, date_times } = item
          const dateRange: { date: string, start: string, end: string } = date_times[0]
          return (
            <div className={zone} key={key}>
              <div className={check}><MuiCheckbox /> <div style={{ fontSize: '15px' }}>{name}</div></div>
              <div className={date}>
                <div>
                  <div className={dateTitle}>Datum</div>
                  <div className={dateContent}>{dateRange?.date}</div>
                </div>
                <div>
                  <div className={dateTitle}>Uhrzeit</div>
                  <div className={dateContent}>{dateRange?.start}-{dateRange?.end}</div>
                </div>
                <div>
                  <div className={dateTitle}>Adresse</div>
                  <div className={dateContent}>{street} {housenumber}</div>
                  <div className={dateContent}>{plz} {city}</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )

  const DialogConfirmContent = () => (
    <div className={confirmModal}>
      <CustomIcon name={'done'} fill={'#B0BEC5'} size={'64px'} />
      <div>
        <div style={{ fontWeight: 400, fontSize: '40px' }}>Erfolgreich!</div>
        <div>Die Präventionsmaßnahmen wurden an den Nutzer versandt. </div>
      </div>
    </div>
  )

  const heartAttackProbability = Number(currentUserTab.user.inference_result.probability
    .toFixed(3).toString().split('.').pop()) / 10.0
  const heartAttackRiskLevel = heartAttackLevel(heartAttackProbability)
  const descriptionRisk = heartAttackRiskLevel == 2 ? "niedrig" : heartAttackRiskLevel == 1 ? "moderat" : "hoch"

  return (
    <div className={aiProposalTab}>
      <div className={header}>Herzinfarktrisiko</div>
      <div className={content}>
        <div className={contentText}>
          <div style={{ width: '50%', fontSize: '12px' }}>
            Nach Analyse der Krankenhistorie hat das KI-Modell
            das Herzinfarktrisiko für diesen Versicherten als {descriptionRisk} eingestuft. <span style={{ color: heartAttackColor(heartAttackProbability) }} >({heartAttackProbability} von 100 möglichen Punkten)</span>
          </div>
          <div style={{ marginLeft: "50px" }}>
            {heartAttackIcon(heartAttackProbability, '48px', heartAttackColor(heartAttackProbability))}
          </div>
        </div>
      </div>
      <div className={additionalContent}>
        Nachfolgend finden Sie die Top 5 Diagnosen, die für ein höheres Herzinfarktrisiko sprechen.
      </div>
      <div>
        <MuiTable cells={proposalCells} rows={currentUserTab.user.inference_result.reasons} />
      </div>
      <div className={button}><MuiButton onClick={onOpenModal} content={'Empfehle Präventionsmaßnahmen'} /></div>
      <MuiModal
        isOpen={isOpenModal}
        onClose={onCloseModal}
        onConfirm={onConfirmModal}
        buttonTitle={'Empfehle Präventionsmaßnahmen'}
        title={'Präventionsmaßnahmen'}
        content={<DialogContent />}
      />
      <MuiModal
        isOpen={isOpenConfirm}
        onClose={onCloseConfirmModal}
        onConfirm={onCloseConfirmModal}
        buttonTitle={'Schließen'}
        title={'Präventionsmaßnahmen'}
        content={<DialogConfirmContent />}
      />
    </div >
  )
}

export default AiProposalTab