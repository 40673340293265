import React from 'react'
import CustomIcon from "../components/custom-icon"

const ColorGenerator = function* (): Generator<string> {
  while (true) {
    yield '#FFA903'
    yield '#00A3FF'
    yield '#F90984'
    yield '#D709F9'
    yield '#79D9DB'
    yield '#D709F9'
    yield '#73C354'
  }
}

const colorGen = ColorGenerator()

export const getRandomColor = () => {
  return colorGen.next().value
}

export const deepValue = (obj: object, path: string[] = []) => {
  const value = path.reduce((obj, propName) => obj?.[propName], obj)
  return value
}

export const random = (a: number, b: number) => {
  return a + (b - a) * Math.random()
}

export const randomId = () => {
  const a = random(0, 10)
  const b = random(10, 20)
  return random(a, b)
}

function rgbToHex(colorRgb: number[]): string {
  return colorRgb.map(c => c.toString(16).split(".")[0].padStart(2, "0")).join('')
}

export const heartAttackColor = (risk: number) => {
  const dark_green = [30, 200, 30]
  const dark_red = [230, 10, 10]
  const yellow = [230, 200, 20]
  risk = risk / 100.0
  let hex: string
  const yellow_risk = 0.25
  if (risk < yellow_risk) {
    let lambda = risk / yellow_risk
    let inv_lambda = 1 - lambda
    let color = [0, 1, 2].map(i => dark_green[i] * inv_lambda + yellow[i] * lambda)
    hex = rgbToHex(color)
  } else if (risk > 0.7) {
    hex = rgbToHex(dark_red)
  } else {
    let lambda = (risk - yellow_risk) / (1 - yellow_risk - 0.3)
    let inv_lambda = 1 - lambda
    let color = [0, 1, 2].map(i => yellow[i] * inv_lambda + dark_red[i] * lambda)
    hex = rgbToHex(color)
  }

  return "#" + hex
}

export const heartAttackIcon = (heartAttackProbability: number, size = '32px', fill = '') => {
  if (heartAttackProbability < 20) {
    return <CustomIcon name={'yes-check-circle'} size={size} fill={fill ? fill : '#4CAF50'} />
  } else {
    return <CustomIcon name={'warning'} size={size} fill={fill ? fill : '#FF9800'} />
  }
}

export const heartAttackLevel = (heartAttackProbability: number, size = '32px') => {
  if (heartAttackProbability < 5) {
    return 2
  } else if (heartAttackProbability >= 5 && heartAttackProbability < 40) {
    return 1
  } else return 0
}
