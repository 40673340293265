import React from 'react'
import styles from "./styles.module.scss"
import MuiTabs from "../../components/mui-tabs"
import OverviewTab from "./overview-tab"
import AiProposalTab from "./ai-proposal-tab"
import DashboardTab from "./dashboard-tab"

const {
  'users-content__tabs': tabs,
} = styles

const ArbeitsbereichTabs = () => {
  const contentTabs = [
    {
      value: 'Overview',
      label: 'Übersicht',
      closable: false,
      content: <OverviewTab />,
      id: 1,
    },
    {
      value: 'Tasks',
      label: 'Aufgaben (12)',
      closable: false,
      content: '',
      id: 2,
      disabled: true,
    },
    {
      value: 'Kontakthistorie',
      label: 'Kontakthistorie',
      closable: false,
      content: '',
      id: 3,
      disabled: true,
    },
    {
      value: 'Dashboard',
      label: 'Dashboard',
      closable: false,
      content: <DashboardTab />,
      id: 4
    },
    {
      value: 'AI proposal',
      label: 'KI-Analyse (1)',
      closable: false,
      content: <AiProposalTab />,
      id: 5
    },
  ]
  return (
    <div className={tabs}>
      <MuiTabs tabsContent={contentTabs} isShowSettings={true} />
    </div>
  )
}

export default ArbeitsbereichTabs