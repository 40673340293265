import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import {ReactNode} from "react"
import MuiButton from "../mui-button"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width: '600px',
    padding: theme.spacing(2),
    fontFamily: 'OpenSans, sans-serif'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    fontFamily: 'OpenSans, sans-serif'
  },
}))

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label={"close"}
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

type modalType = {
  isOpen: boolean,
  onClose: () => void,
  onConfirm: () => void
  title: string,
  buttonTitle?: string,
  content?: ReactNode
}

export const MuiModal = (props: modalType) => {
  const { isOpen, onClose, title, content, buttonTitle, onConfirm } = props
  return (
    <>
      {isOpen ? <div>
        <BootstrapDialog
          onClose={onClose}
          aria-labelledby={"customized-dialog-title"}
          open={isOpen}
        >
          <BootstrapDialogTitle id={"customized-dialog-title"} onClose={onClose}>
            {title}
          </BootstrapDialogTitle>
          <DialogContent>
            {content}
          </DialogContent>
          <DialogActions>
            <span><MuiButton content={buttonTitle} size={'small'} onClick={onConfirm} /></span>
          </DialogActions>
        </BootstrapDialog>
      </div> : null}
    </>
  )
}

export default MuiModal