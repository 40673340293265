import axios from "axios"
import { updateCommon } from "../slices/common-slice"

export const getUsers = () => async (dispatch: any) => {
  await axios.get('https://clickdummy-dev.aisf.t-systems.net/backend/users')
    .then((response) => {
      dispatch(updateCommon({ users: response.data }))
    })
    .catch((error: any) => {
      console.log(error)
    })
}

export const getUser = (user_id?: number) => async (dispatch: any) => {
  await axios.get(`https://clickdummy-dev.aisf.t-systems.net/backend/users/${user_id}`)
    .then((response) => {
      dispatch(updateCommon({ user: response.data }))
    })
    .catch((error: any) => {
      console.log(error)
    })
}

export const getPreventionMeasures = (user_id?: number) => async (dispatch: any) => {
  await axios.get(`https://clickdummy-dev.aisf.t-systems.net/prevention_measures/${user_id}`)
    .then((response) => {
      dispatch(updateCommon({ prevention_measures: response.data }))
    })
    .catch((error: any) => {
      console.log(error)
    })
}