import React from 'react'
import chart from '../../images/chart.jpg'
import chart2 from '../../images/chart (2).jpg'
import chart3 from '../../images/chart (1).jpg'
import styles from "./styles.module.scss"

const {
  'users-content__dashboard-tab': dashboard,
  'users-content__dashboard-tab__wrapper': wrapper
} = styles

const DashboardTab = () => {
  return (
    <div className={dashboard}>
      <div className={wrapper}><img src={chart2} alt={''} height={'400'} width={'100%'} /></div>
      <div className={wrapper}><img src={chart3} alt={''} height={'400'} width={'100%'} /></div>
      <div className={wrapper}><img src={chart} alt={''} height={'400'} width={'100%'} /></div>
    </div>
  )
}

export default DashboardTab