import React from 'react'
import MuiTabs from "../../components/mui-tabs"
import {useSelector} from "react-redux"
import {TStore} from "../../store"

const ArbeitsbereichPage = () => {
  const {
    tabs
  } = useSelector((state: TStore) => state.commonReducer)
  return (<div>
    {tabs.length > 0
      ? <MuiTabs tabsContent={tabs} />
      : <div className={'on-center'} style={{margin: '2rem', fontSize: '20px', color: '#78909C'}}>
        KEIN AKTIVER ARBEITSBEREICH
        </div>}
  </div>)
}

export default ArbeitsbereichPage