import React, { useEffect, useState } from 'react'
import { updateTabs, deleteTab, updateCommon } from "../../slices/common-slice"
import { useSelector } from "react-redux"
import { TStore } from "../../store"
import { useNavigate } from "react-router-dom"
import ArbeitsbereichContent from "../arbeitsbereich/arbeitsbereich-content"
import { getUsers } from "../../domain/api"
import { useAppDispatch } from "../../hooks"
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { OutlinedInput, styled, FormControl, InputAdornment } from "@mui/material"
import CustomIcon from "../../components/custom-icon"
import { heartAttackColor, heartAttackIcon } from "../../domain/utils"
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import userEvent from '@testing-library/user-event'

type UserType = {
  age: number,
  firstname: string,
  geburtsdatum: string,
  letzter_kontakt: string,
  bearbeiter: string,
  insured_since: string,
  kv_number: string,
  lastname: string,
  rv_number: string,
  telefon: string,
  inference_result: {
    probability: number,
    reasons: []
  },
}

const CssOutlinedInput = styled(OutlinedInput)({
  '& input:valid + fieldset': {
    borderColor: '#546E7A',
    borderWidth: 1,
  },
  '& input:invalid + fieldset': {
    borderColor: '#546E7A',
    borderWidth: 1,
  },
  '& input:valid:focus + fieldset': {
    borderColor: '#546E7A',
  },
})

function descendingComparator(a: UserType, b: UserType, orderBy: string) {
  let left: any, right: any
  if (orderBy === "inference_result") {
    left = a.inference_result.probability, right = b.inference_result.probability
  } else if (orderBy === "firstname") {
    left = fullName(a), right = fullName(b)
  } else {
    left = a[orderBy], right = b[orderBy]
  }
  if (typeof left === 'string' && left.includes(".")) {
    left = reorderDate(left as string), right = reorderDate(right as string)
  }
  if (typeof left === 'string' && (left.includes("Tag") || left.includes("Woche"))) {
    left = vorToDate(left as string), right = vorToDate(right as string)
  }
  if (right < left) {
    return -1;
  }
  if (right > left) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function reorderDate(left: string): string {
  return left.split(".").reverse().join('')
}

function vorToDate(left: string): string {
  if (left === "") {
    return left
  }
  let tag: string = left.includes("W") ? "w" : "t"
  return tag + left.split(" ")[1].padStart(2, "0")
}

function getComparator(
  order: Order,
  orderBy: string,
): (
  a: UserType,
  b: UserType
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'geburtsdatum',
    numeric: false,
    disablePadding: false,
    label: 'Geburtsdatum',
  },
  {
    id: 'kv_number',
    numeric: false,
    disablePadding: false,
    label: 'KV-Nr.',
  },
  {
    id: 'telefon',
    numeric: false,
    disablePadding: false,
    label: 'Telefon',
  },
  {
    id: 'insured_since',
    numeric: false,
    disablePadding: false,
    label: 'Versichert seit',
  },
  {
    id: 'bearbeiter',
    numeric: false,
    disablePadding: false,
    label: 'Zuständiger Mitarbeiter',
  },
  {
    id: 'letzter_kontakt',
    numeric: false,
    disablePadding: false,
    label: 'Letzter Kontakt',
  },
  {
    id: 'inference_result',
    numeric: false,
    disablePadding: false,
    label: 'Herzinfarktrisiko',
  },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: '14%' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  searchedVal: string;
  setSearchedVal: (any: string) => void
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, searchedVal, setSearchedVal } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >

      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Alle Versicherten
      </Typography>

      <div style={{
        marginTop: "10px",
        marginRight: "8px",
        padding: "3px",
        borderRadius: "3px",
        border: "1px solid #546E7A"
      }}>
        <div style={{ marginTop: "3px" }}>
          <FilterAltIcon style={{ fill: "#546E7A" }} />
        </div>
      </div>
      <FormControl sx={{ width: '280px', marginTop: '10px' }}>
        <CssOutlinedInput
          value={searchedVal}
          size={'small'}
          onChange={(e) => setSearchedVal(e.target.value)}
          placeholder={'Suche nach Namen'}
          startAdornment={<InputAdornment position={"start"}>
            <CustomIcon name={'search'} fill={'#546E7A'} />
          </InputAdornment>}
        />
      </FormControl>
    </Toolbar>
  );
}


type tableType = {
  rows: UserType[],
  onClick?: (item: {}) => void,
  size?: 'small' | 'medium' | undefined
}

function fullName(user: UserType): string {
  return user.firstname + " " + user.lastname
}

function recentlyContacted(user: UserType): boolean {
  return user.bearbeiter !== "" || user.letzter_kontakt.includes("Tag") || user.letzter_kontakt.includes("Woche") && Number(user.letzter_kontakt.split(" ")[1]) <= 6
}

const MuiTableSort = (props: tableType) => {
  const { rows, onClick, size = 'medium' } = props

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('firstname');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchedVal, setSearchedVal] = React.useState("");

  const filtered_rows = rows.filter((row) => {

    return !searchedVal.length || fullName(row)
      .toString()
      .toLowerCase()
      .includes(searchedVal.toString().toLowerCase())
  }
  )

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = (orderBy === property && order === 'asc') || (orderBy !== property && ["inference_result", "letzter_kontakt", "bearbeiter"].includes(property));
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filtered_rows.length) : 0;

  const recentlyContactedStyle = { background: '#EEEEEE', color: "#ABABAB" }
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={0} searchedVal={searchedVal} setSearchedVal={setSearchedVal} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filtered_rows.length}
            />
            <TableBody>
              {filtered_rows.sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={fullName(row)}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        onClick={() => onClick ? onClick(row) : null}
                        style={recentlyContacted(row) ? recentlyContactedStyle : {}}
                      >
                        {fullName(row)}
                      </TableCell>
                      {["geburtsdatum", "kv_number", "telefon", "insured_since", "bearbeiter", "letzter_kontakt"].map((accessor: string, key) => (
                        <TableCell key={key} onClick={() => onClick ? onClick(row) : null} style={recentlyContacted(row) ? recentlyContactedStyle : {}}>
                          {row[accessor]}
                        </TableCell>
                      ))}

                      <TableCell onClick={() => onClick ? onClick(row) : null} style={recentlyContacted(row) ? recentlyContactedStyle : {}}>
                        <div style={{ marginRight: "22px" }}>
                          {heartAttackIcon(row.inference_result.probability * 100.0, "28px", heartAttackColor(row.inference_result.probability * 100.0))}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 61 * emptyRows,
                  }}
                >
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

const UsersPage = () => {
  const dispatch = useAppDispatch()
  const history = useNavigate()
  const { users } = useSelector((state: TStore) => state.commonReducer)

  useEffect(() => {
    dispatch(getUsers())
  }, [])

  const onClick = async (item: any) => {
    const { user_id } = item
    const onDeleteTab = () => {
      dispatch(deleteTab(user_id))
    }
    await dispatch(updateTabs({
      user: item,
      value: item.firstname,
      label: `${item.lastname}, ${item.firstname}`,
      closable: true,
      content: <ArbeitsbereichContent />,
      id: item.user_id,
      onDelete: onDeleteTab
    }))
    await dispatch(updateCommon({
      currentUserTab: {
        user: item,
        value: item.firstname,
        label: item.firstname,
        closable: true,
        content: <ArbeitsbereichContent />,
        id: item.user_id,
        onDelete: onDeleteTab
      }
    }))
    history('/arbeitsbereich')
  }


  return (<div>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}
    >
      <div style={{
        fontWeight: '400',
        fontSize: '24px'
      }}
      />
    </div>
    <MuiTableSort rows={users} onClick={onClick} />
  </div>)
}

export default UsersPage