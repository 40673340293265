import React, { FunctionComponent } from 'react'
import styles from './style.module.scss'

import {
  CloseIcon, UserIcon, SearchIcon, FaceBookIcon, SomeAppLogoIcon, LinkInIcon, TwitterIcon,
  GlobusLogo, DateLogo, PeopleLogo, PersonLogo, PersonsLogo, PhoneLogo, ListLogo,
  EyeLogo, FlagLogo, LocationLogo, SettingsIcon, WarningIcon, WorkOutlineIcon,
  GroupIcon, ContactsIcon, AddCircleIcon, FilePresentIcon, ErrorIcon, NewIcon, DoneIcon,
  ArrowRightIcon, RectangleIcon, YesCheckCircleIcon, FilterIcon, AiIcon
} from '../../icons'

const {
  'custom-icon': icon,
} = styles

type IconProps = {
  name?: string,
  size?: string,
  fill?: string,
  className?: string,
  viewBox?: string,
}

type FC<P = {}> = FunctionComponent<P>

const CustomIcon = ({
  size = '24px', fill = 'currentColor', name = 'default', className = icon, viewBox = '0 0 24 24'
}: IconProps) => {
  const iconProps = { width: size, height: size, fill, viewBox, className }
  const Icon: FC<IconProps> = {
    close: CloseIcon,
    user: UserIcon,
    search: SearchIcon,
    facebook: FaceBookIcon,
    twitter: TwitterIcon,
    linkin: LinkInIcon,
    'some-app-logo': SomeAppLogoIcon,
    globus: GlobusLogo,
    date: DateLogo,
    people: PeopleLogo,
    person: PersonLogo,
    persons: PersonsLogo,
    phone: PhoneLogo,
    list: ListLogo,
    eye: EyeLogo,
    flag: FlagLogo,
    location: LocationLogo,
    settings: SettingsIcon,
    warning: WarningIcon,
    'work-outline': WorkOutlineIcon,
    group: GroupIcon,
    contacts: ContactsIcon,
    'add_circle': AddCircleIcon,
    'file_present': FilePresentIcon,
    error: ErrorIcon,
    new: NewIcon,
    done: DoneIcon,
    'arrow_right': ArrowRightIcon,
    rectangle: RectangleIcon,
    'yes-check-circle': YesCheckCircleIcon,
    filter: FilterIcon,
    ai: AiIcon,
  }[name]!

  return (
    <span className={className}>
      <Icon {...iconProps} />
    </span>
  )
}

export default CustomIcon