import React from 'react'
import { Button, ButtonProps, styled } from "@mui/material"

const ColorButton = styled(Button)<ButtonProps>(() => ({
  color: '#FFFFFF',
  backgroundColor: '#78909C',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#78909C',
  },
}))

type ButtonMuiProps = {
  content: string | undefined,
  size?: 'small' | 'medium' | 'large',
  onClick?: () => void
  disabled?: boolean
}

const MuiButton = (props: ButtonMuiProps) => {
  const { content, size = 'medium', onClick, disabled = false } = props
  return (
    <ColorButton size={size} variant={"contained"} disabled={disabled} onClick={onClick}>{content}</ColorButton>
  )
}

export default MuiButton