import React from "react"
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Toolbar from '@mui/material/Toolbar';
import { styled } from "@mui/material"
import styles from "./style.module.scss"

const {
  'mui-table__wrapper': tableWrapper,
} = styles

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: '12px',
    lineHeight: '0.5',
    align: 'left',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}))

const changeImpact = (row: number) => {
  if (row > 0.005 && row < 0.025) {
    return <span style={{ color: '#FF9800' }}>Mittlerer Risikoindikator</span>
  } else if (row >= 0.025) {
    return <span style={{ color: '#F44336' }}>Starker Risikoindikator</span>
  } else {
    return <span style={{ color: '#4CAF50' }}>Schwacher Risikoindikator</span>
  }
}

const onTableRow = (row: {}, cells: { header: string, accessor: string | string[] }[], onClick?: () => void) => {
  return cells
    .map((cell: { header: string, accessor: string | number | string[] }, iCell: React.Key | null | undefined) => {
      if (Array.isArray(cell.accessor)) {
        if (row !== StyledTableCell) {
          return <StyledTableCell
            key={iCell}
            align={"left"}
            onClick={onClick}
          >{cell.accessor.map((item) => {
            return row[item] + ' '
          }) || []}
          </StyledTableCell>
        }
      } else {
        if (row !== StyledTableCell) {
          if (cell.header === 'Einfluss auf Risikoschätzung') {
            return <StyledTableCell
              key={iCell}
              align={"left"}
              onClick={onClick}
            >{changeImpact(row[cell?.accessor])}
            </StyledTableCell>
          } else {
            return <StyledTableCell
              key={iCell}
              align={"left"}
              onClick={onClick}
            >{row[cell?.accessor] || []}
            </StyledTableCell>
          }
        }
      }
    })
}

type tableType = {
  cells: { header: string, accessor: string | string[] }[],
  rows: {}[],
  onClick?: (item: {}) => void,
  size?: 'small' | 'medium' | undefined
}


const MuiTable = (props: tableType) => {
  const { cells, rows, onClick, size = 'medium' } = props
  return (
    <div className={tableWrapper}>
      <TableContainer>
        <Table sx={{ width: '100%' }} size={size} aria-label={"simple table"}>

          <TableHead>
            <TableRow>
              {cells.map((item, key) => {
                return <TableCell key={key}>
                  <div style={{ fontWeight: '700' }}>{item.header}</div>
                </TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any, key) => (
              <TableRow
                key={key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                hover
                style={{ cursor: 'pointer' }}
              >
                {onTableRow(row, cells, () => onClick ? onClick(row) : null)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default MuiTable