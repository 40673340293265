import { createSlice } from "@reduxjs/toolkit"
import { ReactNode } from "react"

type UserType = {
  age: number,
  bic: string,
  city: string
  firstname: string,
  geburtsdatum: string,
  letzter_kontakt: string,
  bearbeiter: string,
  had_heart_attack: boolean,
  high_risk_heart_attack: boolean,
  housenumber: string,
  iban: string,
  gender: string,
  insured_since: string,
  kreditinstitut: string,
  kv_number: string,
  lastname: string,
  offene_forderungen: number,
  offene_verbindlichkeiten: number,
  organisationseinheit: number,
  personengruppe: string,
  plz: string,
  rv_number: string,
  street: string,
  telefon: string,
  user_id: number,
  inference_result: {
    probability: number,
    reasons: []
  },
}

type CurrentUserType = {
  user: UserType,
  value: string,
  id: number,
  label: string,
  content: ReactNode,
  closable: boolean,
  onDelete: (id: number) => void,
}

const concatUniqueTabs = (tabs: any[], payload: any) => {
  const arr = tabs.concat(payload)
  const prepare = Array.from(new Set(arr.map(a => a.id)))
    .map(id => {
      return arr.find(a => a.id === id)
    })
  return prepare.reverse()
}

interface initialInterface {
  tabs: {
    user: UserType,
    value: string,
    id: number,
    label: string,
    content: ReactNode,
    closable: boolean,
    onDelete: (id: number) => void,
  }[]
  users: UserType[]
  prevention_measures: [],
  user: UserType,
  currentUserTab: CurrentUserType,
}

const initialState = {
  tabs: [],
  users: [],
  prevention_measures: [],
  user: {} as UserType,
  currentUserTab: {} as CurrentUserType
} as initialInterface

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateCommon: (state = initialState, action) => ({
      ...state,
      ...action.payload
    }),
    updateTabs: (state = initialState, action) => ({
      ...state,
      tabs: concatUniqueTabs(state.tabs, action.payload)
    }),
    deleteTab: (state = initialState, action) => ({
      ...state,
      tabs: state.tabs.filter((item) => item.id !== action.payload)
    })
  }
})

export const commonReducer = commonSlice.reducer
export const { updateCommon, updateTabs, deleteTab } = commonSlice.actions
