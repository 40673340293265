import React from 'react'
import styles from "./styles.module.scss"
import CustomIcon from "../../components/custom-icon"
import PsychologyIcon from '@mui/icons-material/Psychology';
import MuiTable from "../../components/mui-table"
import MuiButton from "../../components/mui-button"
import { useSelector } from "react-redux"
import { TStore } from "../../store"
import { heartAttackColor, heartAttackIcon, heartAttackLevel } from "../../domain/utils"
import Light from '../../components/light'
const {
  'users-content__overview-tab': overviewTab,
  'users-content__overview-tab__top-wrapper': topWrapper,
  'users-content__overview-tab__bottom-wrapper': bottomWrapper,
  'users-content__overview-tab__ai-proposal': aiProposal,
  'users-content__overview-tab__ai-proposal__content': aiProposalContent,
  'users-content__overview-tab__ai-proposal__data': aiProposalData,
  'users-content__overview-tab__ai-proposal__text': aiProposalText,
  'users-content__overview-tab__ai-proposal__info': aiProposalInfo,
  'users-content__overview-tab__ai-proposal__info__link': aiProposalInfoLink,
  'users-content__overview-tab__kontakthistorie': kontakthistorie,
  'users-content__overview-tab__kontakthistorie__data': kontakthistorieData,
  'users-content__overview-tab__kontakthistorie__info': kontakthistorieInfo,
  'users-content__overview-tab__leistungen': leistungen,
  'users-content__overview-tab__leistungen__data': leistungenData,
  'users-content__overview-tab__leistungen__info': leistungenInfo,
  'users-content__overview-tab__tasks': tasks,
  'users-content__overview-tab__tasks__data': tasksData,
  'users-content__overview-tab__tasks__info': tasksInfo,
  'users-content__overview-tab__header': header,
  'users-content__overview-tab__header-title': headerTitle,
} = styles

const leistungenCells = [
  { header: 'Fallart', accessor: 'fallart' },
  { header: 'Betrag €', accessor: 'betrag' }
]
const createDataLeistungen = (fallart: JSX.Element, betrag: string) => {
  return { fallart, betrag }
}

const leistungenRows = [
  createDataLeistungen(<span style={{ lineHeight: '1' }}>Ärztliche Behandlung</span>, '175.00'),
  createDataLeistungen(<span style={{ lineHeight: '1' }}>Übernahme Arzneimittel</span>, '34.50'),
  createDataLeistungen(<span style={{ lineHeight: '1' }}>Übernahme Hilfsmittel</span>, '78.99'),
  createDataLeistungen(<span style={{ lineHeight: '1' }}>Ärztliche Behandlung</span>, '175.00'),
]

const kontakthistorieCells = [
  { header: 'Datum', accessor: 'kontakt' },
  { header: 'Kontaktart', accessor: 'weg' },
  { header: 'Betreff', accessor: 'betreff' },
  { header: 'Bearbeiter', accessor: 'bearbeiter' },
]
const createDataKontakthistorie = (kontakt: string, weg: JSX.Element,
  betreff: string, bearbeiter: string) => {
  return { kontakt, weg, betreff, bearbeiter }
}

const kontakthistorieRows = [
  createDataKontakthistorie('12.01.2023', <span style={{ lineHeight: '1' }}>App</span>, 'Bestätigung zur Empfehlung von Präventionsmaßnahmen', 'Herr Sachbearbeiter'),
  createDataKontakthistorie('08.01.2023', <span style={{ lineHeight: '1' }}>Brief</span>, 'Rückfrage zu Abrechnung', ''),
  createDataKontakthistorie('07.01.2023', <span style={{ lineHeight: '1' }}>Brief</span>, 'Neue Versichertenkarte', ''),
  createDataKontakthistorie('15.12.2022', <span style={{ lineHeight: '1' }}>Brief</span>, 'Rezeptauszahlung', 'Herr Sachbearbeiter')
]

const taskCells = [
  { header: 'Prio', accessor: 'prio' },
  { header: 'Status', accessor: 'status' },
  { header: 'Aufgabe', accessor: 'aufgabe' },
  { header: 'Datum', accessor: 'erzeugt' },
  { header: 'Dokument', accessor: 'dokument' },
  { header: 'Zuständigkeit', accessor: 'zustandigkeit' },
]
const createDataTasks = (prio: JSX.Element | string, status: JSX.Element | string,
  aufgabe: string, erzeugt: string, dokument: JSX.Element | string,
  zustandigkeit: JSX.Element | string) => {
  return { prio, status, aufgabe, erzeugt, dokument, zustandigkeit }
}

const tasksRow = [
  createDataTasks(<span style={{ background: '#F44336', border: '1px solid', borderRadius: '3px', padding: '3px', color: '#FFFFFF' }}>5</span>, <CustomIcon className={'content-left'} name={'new'} size={'16px'} />, 'Leistungsabrechnung #92318 fehlerhaft', '08.01.2023', <CustomIcon className={'content-left'} name={'file_present'} size={'16px'} fill={'#78909C'} />, <CustomIcon className={'content-left'} name={'add_circle'} size={'16px'} fill={'#78909C'} />),
  createDataTasks(<span style={{ background: '#FF9800', border: '1px solid', borderRadius: '3px', padding: '3px', color: '#FFFFFF' }}>4</span>, <CustomIcon className={'content-left'} name={'new'} size={'16px'} />, 'Rückfrage #12345', '14.01.2023', <CustomIcon className={'content-left'} name={'file_present'} size={'16px'} fill={'#78909C'} />, <CustomIcon className={'content-left'} name={'add_circle'} size={'16px'} fill={'#78909C'} />),
  createDataTasks(<span style={{ background: '#546E7A', border: '1px solid', borderRadius: '3px', padding: '3px', color: '#FFFFFF' }}>3</span>, <CustomIcon className={'content-left'} name={'new'} size={'16px'} />, 'Leistungabrechnung #92123', '09.01.2023', <CustomIcon className={'content-left'} name={'file_present'} size={'16px'} fill={'#78909C'} />, <CustomIcon className={'content-left'} name={'add_circle'} size={'16px'} fill={'#78909C'} />),
  createDataTasks(<span style={{ background: '#546E7A', border: '1px solid', borderRadius: '3px', padding: '3px', color: '#FFFFFF' }}>3</span>, <CustomIcon className={'content-left'} name={'new'} size={'16px'} />, 'Auszahlung bestätigen #91894', '11.01.2023', '', <CustomIcon className={'content-left'} name={'add_circle'} size={'16px'} fill={'#78909C'} />),
]

const OverviewTab = () => {
  const { currentUserTab } = useSelector((state: TStore) => state.commonReducer)
  const heartAttackProbability = Number(currentUserTab.user.inference_result.probability
    .toFixed(3).toString().split('.').pop()) / 10.0
  const heartAttackRiskLevel = heartAttackLevel(heartAttackProbability)
  const descriptionRisk = heartAttackRiskLevel == 2 ? "niedrig" : heartAttackRiskLevel == 1 ? "moderat" : "hoch"
  return (
    <div className={overviewTab}>
      <div className={topWrapper}>
        <div className={aiProposal}>
          <div className={header}>
            <PsychologyIcon style={{ fill: '#78909C', width: '24px', height: '24px' }} />
            <div className={headerTitle}>KI-Analyse</div>
          </div>
          <div className={aiProposalContent}>
            <div className={aiProposalData}>
              <div style={{ width: '40%' }}>Herzinfarktrisiko</div>
              <div style={{ marginLeft: "auto" }}>
                {heartAttackIcon(heartAttackProbability, "64px", heartAttackColor(heartAttackProbability))}
              </div>
            </div>
            <div className={aiProposalText}>Nach Analyse der Krankenhistorie hat das KI-Modell
              das Herzinfarktrisiko für diesen Versicherten als {descriptionRisk} eingestuft. <span style={{ color: heartAttackColor(heartAttackProbability) }} >({heartAttackProbability} von 100 möglichen Punkten)</span></div>
            <div className={aiProposalInfo}>
              <div className={aiProposalInfoLink}><span style={{ marginRight: '4px' }}>Mehr anzeigen</span><CustomIcon name={'arrow_right'} fill={"#78909C"} size={'10px'} viewBox={'0 0 10 10'} /></div>
              <MuiButton content={'Versicherten informieren'} size={'small'} />
            </div>
          </div>
        </div>
        <div className={kontakthistorie}>
          <div className={header}>
            <CustomIcon name={'contacts'} fill={'#78909C'} size={'18px'} />
            <div className={headerTitle}>Kontakthistorie</div>
          </div>
          <div className={kontakthistorieData}>
            <MuiTable cells={kontakthistorieCells} rows={kontakthistorieRows} size={'small'} />
          </div>
          <div className={kontakthistorieInfo}><span style={{ marginRight: '4px' }}>Mehr anzeigen</span><CustomIcon name={'arrow_right'} fill={"#78909C"} size={'10px'} viewBox={'0 0 10 10'} /></div>
        </div>
      </div>
      <div className={bottomWrapper}>
        <div className={leistungen}>
          <div className={header}>
            <div style={{ marginTop: "3px" }}>
              <CustomIcon name={'group'} fill={'#78909C'} size={'18px'} />
            </div>
            <div className={headerTitle}>Leistungen</div>
          </div>
          <div className={leistungenData}>
            <MuiTable cells={leistungenCells} rows={leistungenRows} size={'small'} />
          </div>
          <div className={leistungenInfo}><span style={{ marginRight: '4px' }}>Mehr anzeigen</span><CustomIcon name={'arrow_right'} fill={"#78909C"} size={'10px'} viewBox={'0 0 10 10'} /></div>
        </div>
        <div className={tasks}>
          <div className={header}>
            <CustomIcon name={'work-outline'} fill={'#78909C'} size={'18px'} />
            <div className={headerTitle}>Aufgaben</div>
          </div>
          <div className={tasksData}>
            <MuiTable cells={taskCells} rows={tasksRow} size={'small'} />
          </div>
          <div className={tasksInfo}><span style={{ marginRight: '4px' }}>Mehr anzeigen</span><CustomIcon name={'arrow_right'} fill={"#78909C"} size={'10px'} viewBox={'0 0 10 10'} /></div>
        </div>
      </div>
    </div>
  )
}

export default OverviewTab